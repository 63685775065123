<template>
  <aw-modal
    v-if="modal && modal.item && modal.item.data && modal.item.data.staticContentType"
    :opened="opened"
    :closable="closable"
    size="lg"
    :modal-title="modal.item.data.staticContentType.title"
    @close-modal="closeModal"
  >
    <template #content>
      <aw-static-modal-content
        ref="staticModalContent1"
        :content-end-point-url="`/static_content_pages/${modal.item.data.staticContentType.content}`"
      />
    </template>
  </aw-modal>
</template>

<script>
  import { mapState } from 'pinia';
  import { M_STATIC_CONTENT } from '~~/common/config/Modal.js';
  import AwStaticModalContent from '~~/common/components/Common/Modals/AwStaticModalContent';
  import AwModal from '~~/common/components/Common/AwModal';

  import { useModalStore } from '~~/common/stores/modal';

  export default {
    name: 'AwStaticContentModal',
    components: {
      AwModal,
      AwStaticModalContent,
    },
    computed: {
      ...mapState(useModalStore, {
        modal: state => state.activeGenericModal,
      }),
      opened () {
        return this.modal.type === M_STATIC_CONTENT;
      },
      closable () {
        return this.modal.item.closable;
      },
    },
    methods: {
      closeModal () {
        const modalStore = useModalStore();
        modalStore.closeStaticContentModal();
      },
    },
  };
</script>
